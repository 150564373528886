<template>
    <div id="gallery">
        <page-header title="Gallery"/>
        <section id="latest-videos" v-waypoint="{ active: true, callback: onWaypointLatestVideos}">
            <h1 class="title">Recent Videos</h1>
            <p class="subtitle">Becoming Who God Created You</p>
            <latest-videos/>
        </section>
        <playlists/>
        <subscribe-youtube id="subscribe-youtube"/>
        <section id="subscribe-newsletter">
            <subscribe-newsletter/>
        </section>
    </div>
</template>

<script>
    import PageHeader from "../components/comm/PageHeader";
    import LatestVideos from "../components/gallery/LatestVideos";
    import Playlists from "../components/gallery/Playlists";
    import SubscribeYoutube from "../components/comm/SubscribeYoutube";
    import SubscribeNewsletter from "../components/comm/NewsletterSubscriptionForm";
    import AOS from 'aos';
    import 'aos/dist/aos.css';

    export default {
        name: "Gallery",
        components: {SubscribeNewsletter, SubscribeYoutube, Playlists, LatestVideos, PageHeader},
        mounted() {
            /*#subscribe-youtube move to #playlists middle*/
            const playlistSections = document.querySelectorAll("#playlists section");
            const el = playlistSections[Math.trunc(playlistSections.length / 2)];

            document.querySelector("#playlists")
                .insertBefore(document.querySelector("#subscribe-youtube"), el);

            /*AOS Animation initiate*/
            document.querySelectorAll("section").forEach(section => {
                section.setAttribute("data-aos", "fade-up");
                section.setAttribute("data-aos-offset", "200");
                section.setAttribute("data-aos-delay", "50")
            });
            AOS.init()
        }, methods: {
            onWaypointLatestVideos() {
                let currentlyPlaying = this.$store.state.currentlyPlaying;
                if (currentlyPlaying !== null) {
                    currentlyPlaying.pauseVideo()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    #latest-videos {
        margin-top: 4rem;
    }

    #subscribe-newsletter {
        margin-bottom: 15em;
    }
</style>
