<template>
    <div class="youtube">
        <div class="youtube__title">{{ $store.getters.CHANNEL.channel_title }}</div>
        <div class="youtube__description">{{ $store.getters.CHANNEL.channel_description }}</div>
        <subscribe-button/>
    </div>
</template>

<script>
    import SubscribeButton from "./SubscribeButton";
    export default {
        name: "SubscribeYoutube",
        components: {SubscribeButton},
    }
</script>

<style lang="scss" scoped>
    .youtube{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5rem 1rem;
        background-image: linear-gradient(45deg,rgba(255,115,0,1),rgba(218,145,0,1));

        &__title{
            text-align: center;
            font-size: 2.5rem;
            font-weight: bold;
            font-style: italic;
            color: #FFFFFF;
        }

        &__description{
            font-size: 1rem;
            text-align: justify;
            margin: 2rem 0;
            font-weight: 200;
            color: white;
        }

        &__subscribe{
            margin: .8rem auto;
            color: white;
            background-color: #c00;
            padding: .5rem 1.2rem;
        }
    }

    @media (min-width: 1024px) {
        .youtube {
            padding: 5rem 8rem;
            &__description{
                text-align: center;
            }
        }
    }
</style>
