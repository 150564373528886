<template>
    <div class="playlist">
        <div><h1 class="playlist__title"> {{ title }}</h1></div>
        <div class="playlist__description">
            <p>{{ description }}</p>
        </div>
        <div class="playlist__items" v-if="playlistItems.filter( item => item.snippet.playlistId === playlistId).length > 0">
            <card
                    v-for="item in playlistItems.filter( item => item.snippet.playlistId === playlistId)"
                    :key="item.id"
                    :video-id="item.snippet.resourceId.videoId"
                    :title="item.snippet.title"
                    :video-description="item.snippet.description"
                    :thumbnails="item.snippet.thumbnails"
                    :published-at="item.snippet.publishedAt"
            />
        </div>
        <button class="playlist__watchMore" @click="playPlaylist">Watch Playlist</button>

        <playlist-model :ref="`${playlistId}model`" :title="title" :description="description"
                        :published-at="publishedAt" :playlist-id="playlistId" :thumbnails="thumbnails"/>
    </div>
</template>

<script>
    import Card from "./Card";
    import PlaylistModel from "../comm/PlaylistModel";
    import { mapState } from 'vuex'

    export default {
        name: "Playlist",
        components: {PlaylistModel, Card},
        props: {
            playlistId: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: true
            },
            publishedAt: {
                type: String,
                required: true
            },
            thumbnails: {
                type: Object | String
            }
        },
      computed: mapState(['playlistItems']),
        data() {
            return {
                items: []
            }
        },
        methods: {
            playPlaylist() {
                // this.$refs[`${this.playlistId}model`].open()
              window.open(`https://www.youtube.com/playlist?list=${this.playlistId}`,
                '_blank' // <- This is what makes it open in a new window.
              );
            }
        }
    }
</script>

<style lang="scss" scoped>
    .playlist {
        margin: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__title {
            color: #da9100;
            width: 100%;
            text-align: center;
            position: relative;
            font-size: 2rem;
            margin-bottom: 10px;
            text-transform: uppercase;
        }

        &__description {
            color: #347d7a;
            margin-bottom: 3rem;
            margin-top: 0px;
            text-align: center;
            font-family: 'Barlow', sans-serif;
        }

        &__items {
            display: grid;
            grid-gap: 1.5rem;
            grid-template-columns: 1fr;
            height: 100%;
            width: 100%;
        }

        &__watchMore {
            display: inline-block;
            border: none;
            border-radius: 5px;
            padding: 1rem 2rem;
            margin: 2.5rem 0;
            text-decoration: none;
            background: #da9100;
            color: #ffffff;
            font-family: sans-serif;
            font-size: 1rem;
            line-height: 1;
            cursor: pointer;
            text-align: center;
            transition: background 250ms ease-in-out, transform 150ms ease;
            appearance: none;
        }

        &__watchMore:hover,
        &__watchMore:focus {
            background: #da8417;
        }

        &__watchMore:focus {
            outline: 1px solid #fff;
            outline-offset: -4px;
        }
    }

    @media (min-width: 1200px) {
        .playlist {
            &__items {
                display: grid;
                grid-gap: 1.5rem;
                grid-template-columns: 350px 350px 350px;
            }
        }
    }
</style>
