<template>
    <div id="playlists">
        <section v-for="playlist in $store.state.playlists.filter(list => !list.deleted_at)" :key="playlist.id">
            <playlist
                :playlistId="playlist.id"
                :title="playlist.snippet.title"
                :description="playlist.snippet.description"
                :publishedAt="playlist.snippet.publishedAt"
                :thumbnails="playlist.snippet.thumbnails"
            />
        </section>
    </div>
</template>

<script>
    import Playlist from "./Playlist";
    export default {
        name: "Playlists",
        components: {Playlist},
    }
</script>

<style scoped>
    section:nth-child(odd) {
        background: #F5F5F5;
    }
    section:nth-child(even) {
        background: white;
    }
</style>
