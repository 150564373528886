<template>
    <!--<vue-owl-carousel
            ref="lat-cru" :margin="30"
            :responsive="responsiveData"
            :autoplay="true"
            :items="items"
            :autoplayHoverPause="true"
    >-->
    <div class="owl-carousel owl-theme">
        <card
                v-for="video in latestVideos"
                :key="video.id.videoId"
                :video-id="video.id.videoId"
                :title="video.snippet.title"
                :thumbnails="video.snippet.thumbnails"
                :published-at="video.snippet.publishedAt"
                :video-description="video.snippet.description"
                @playing="playing"
                @paused="paused"
        />
    </div>
    <!--</vue-owl-carousel>-->
</template>

<script>
    // import VueOwlCarousel from "../../custom/vue-owl-carousel";
    import 'owl.carousel/dist/assets/owl.carousel.css';
    import 'owl.carousel';
    import Card from "./Card";

    export default {
        name: "LatestVideos",
        components: {Card},
        data() {
            return {
                items: 3,
                owl: null,
                responsiveData: {
                    0: {
                        items: 1
                    },
                    480: {
                        items: 2
                    },
                    768: {
                        items: 3
                    },
                },
            }
        },
        mounted() {
            let _this = this;
            window.jQuery(document).ready(function () {
                _this.owl = window.jQuery('.owl-carousel').owlCarousel({
                    responsive: _this.responsiveData,
                    autoplayHoverPause: true,
                    autoHeight:true,
                    autoplay: true,
                    margin: 30
                });

                /*_this.owl.on('initialized.owl.carousel', function(event) {
                    console.log('owl initilized')
                })*/
                    document.querySelector('.owl-stage-outer').style.paddingBottom = "50px";
                    document.querySelectorAll('.card').forEach(card => {
                        // card.style.height = '440px';
                    })
            });
        },
        computed: {
            latestVideos() {
                return this.$store.state.latestVideos
            }
        },
        methods: {
            playing() {
                this.owl.trigger('stop.owl.autoplay');
            },
            paused() {
                this.owl.trigger('play.owl.autoplay');
            }
        }
    }
</script>
